import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';

// //
// import enLocales from './en.json';
// import deLocales from './de.json';
// import frLocales from './fr.json';

// ----------------------------------------------------------------------

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID!,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY!,
  refLng: process.env.REACT_APP_LOCIZE_REFLAN!,
  version: process.env.REACT_APP_LOCIZE_VERSION!,
};

const addMissing = Boolean(process.env.REACT_APP_LOCIZE_ADD_MISSING);

i18n
  .use(Backend)
  .use(LanguageDetector)

  .use(initReactI18next)
  .init({
    // resources: {
    //   en: { translations: enLocales },
    //   de: { translations: deLocales },
    //   fr: { translations: frLocales }
    // },
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en',
    debug: false,

    // ns: ['translations'],
    // defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    saveMissing: addMissing,
    backend: locizeOptions,
    react:{
      useSuspense:false
    },
    returnNull: false,
    // returnObjects:true,
    // returnDetails:true
  });

export default i18n;
