import { IContractFolderItem } from "../@types/contract";
import { IInbox } from "../@types/inbox";
import React, { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { privateAxiosInstance } from "utils/axios";

type ISharedContext = {
    inbox: IInbox | null,
    refreshCount: number,
    setRefreshCount: React.Dispatch<React.SetStateAction<number>>,
    folder: IContractFolderItem | null,
    setFolder: React.Dispatch<React.SetStateAction<IContractFolderItem | null>>,
}

const SharedContext = createContext<ISharedContext>({
    inbox: null,
    refreshCount: 0,
    setRefreshCount: (num: number) => { },
    folder: null,
    setFolder: (item: IContractFolderItem | null) => { }
})

type Props = {
    children: ReactNode
}

const AuthSharedContext: React.FC<Props> = ({ children }) => {
    const [inbox, setInbox] = useState<IInbox | null>(null);
    const [refreshCount, setRefreshCount] = useState<number>(0);
    const [folder, setFolder] = useState<IContractFolderItem | null>(null);

    const fetchInbox = useCallback(async () => {
        try {
            const response = await privateAxiosInstance.get('/cs-api/inboxes?isDefault=true')
            setInbox(response?.data)
        } catch (error) {
            console.error(error);
        }
    }, [])

    useEffect(() => {
        fetchInbox()
    }, [fetchInbox])

    return (
        <SharedContext.Provider value={{
            inbox: inbox,
            refreshCount,
            setRefreshCount,
            folder,
            setFolder
        }}>
            {children}
        </SharedContext.Provider>
    )
}

export { AuthSharedContext, SharedContext }