import React, { useEffect, useMemo } from 'react';
import { Avatar, Badge, Box, Button, Typography, Popover } from '@mui/material';

import { Permission } from '../manage-access-popup/manage-access.types';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useSnackbar } from 'notistack';
import useLocales from 'hooks/useLocales';
import { ManageAccessDialog } from '../manage-access-popup/manage-access-popup';
import GroupIcon from '@mui/icons-material/Group';
import _get from 'lodash/get';
import { createGroupPermission, createUserPermission, sortPermissions } from 'utils';
import { IParty, PartiesPayload } from 'pages/contracts/contract-types';
import { IContractItem } from '../../../../@types/contract';
import { useDispatch, useSelector } from 'redux/store';
import { setPermissions } from 'redux/slices/permissions/permissionsSlice';
import { json } from 'stream/consumers';
import { useFetchUserSuggestionsQuery } from 'redux/api/userAPI';
import { useFetchUserGroupsQuery } from 'redux/api/groupAPI';

type Member = {
  id: string;
  name: string;
  type: string;
  email: string;
};

const avatar_colors = [
  { letter: 'A', color: '#ff7961' },
  { letter: 'B', color: '#ba68c8' },
  { letter: 'C', color: '#4db6ac' },
  { letter: 'D', color: '#7986cb' },
  { letter: 'E', color: '#a1887f' },
  { letter: 'F', color: '#e57373' },
  { letter: 'G', color: '#64b5f6' },
  { letter: 'H', color: '#81c784' },
  { letter: 'I', color: '#ffb74d' },
  { letter: 'J', color: '#90a4ae' },
  { letter: 'K', color: '#9575cd' },
  { letter: 'L', color: '#4fc3f7' },
  { letter: 'M', color: '#aed581' },
  { letter: 'N', color: '#dce775' },
  { letter: 'O', color: '#ff8a65' },
  { letter: 'P', color: '#4dd0e1' },
  { letter: 'Q', color: '#4db6ac' },
  { letter: 'R', color: '#f06292' },
  { letter: 'S', color: '#7986cb' },
  { letter: 'T', color: '#a1887f' },
  { letter: 'U', color: '#e57373' },
  { letter: 'V', color: '#64b5f6' },
  { letter: 'W', color: '#81c784' },
  { letter: 'X', color: '#ffb74d' },
  { letter: 'Y', color: '#90a4ae' },
  { letter: 'Z', color: '#9575cd' },
];
type WhoHasAccessProps = {
  contractId: number;
  handleOnOpenManageModal?: () => void;
  contractName: string;
  isManageAccessButton?: boolean;
  openAccessManageModal?: boolean;
  saveLocal?: boolean;
  // handlePermissionsToSave?: React.Dispatch<React.SetStateAction<any[]>>
  partiesOwner?: PartiesPayload[],
  removeUser?: string,
  contract?: IContractItem
};


interface FullPartiesPayload extends PartiesPayload {
  removableOwner?: IParty
}
const getColor = (letter: string) => {
  const color = avatar_colors.find((color) => color.letter === letter.toUpperCase());
  return color ? color.color : '#000';
};

export const WhoHasAccess: React.FC<WhoHasAccessProps> = ({
  contractId,
  contractName,
  openAccessManageModal,
  handleOnOpenManageModal,
  isManageAccessButton = true,
  saveLocal,
  partiesOwner,
  removeUser,
  contract
}) => {


  const privateAxiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const dispatch = useDispatch()
  const { permissions } = useSelector(store => store.permissionsStore)
  const { data: users, isError: isUserError } = useFetchUserSuggestionsQuery()
  const { data: groups, isError: isGroupError } = useFetchUserGroupsQuery()

  useMemo(() => isUserError || isGroupError && enqueueSnackbar(translate('error.serverError', 'Something went wrong!'), { variant: 'error' }), [enqueueSnackbar, isUserError, isGroupError, translate])

  const loadData = async (fetchingURL: string) => {
    const { data } = await privateAxiosInstance.get(fetchingURL);
    return data;
  };

  const [open, setOpen] = React.useState(false);
  const [userList, setUserList] = React.useState<Member[]>([]);

  useEffect(() => {
    if (users) {
      const data: any = users.map((member) => ({
        id: member.id,
        name: _get(member, 'name'),
        type: 'user',
        email: _get(member, 'email'),
      }))

      setUserList(prev => prev.concat(data))
    }
  }, [users])

  useEffect(() => {
    if (groups) {
      const data: any = groups.map((group) => ({
        id: group.id,
        name: _get(group, 'userGroupName'),
        type: 'group',
        email: '',
      }))

      setUserList(prev => prev.concat(data))
    }
  }, [groups])

  useEffect(() => {
    dispatch(setPermissions([]))
  }, [dispatch])

  const handleClose = () => {
    setOpen(false);
    handleOnOpenManageModal && handleOnOpenManageModal();
  };

  useEffect(() => {
    if (openAccessManageModal === true) {
      setOpen(true);
    }
    if (openAccessManageModal === false) {
      setOpen(false);
    }
  }, [openAccessManageModal]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openId, setOpenId] = React.useState<string | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setOpenId(id);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const handleOnOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    (async () => {
      if (contractId) {
        try {
          let type = "contract"
          const { userPermissions, userGroupPermissions } = await loadData(
            `/cs-api/contentPermission/${contractId}?contentType=${type}`
          );

          let usersPermission = userPermissions ? userPermissions.map((user: any) => createUserPermission(user)) : [];

          const groupsPermission = userGroupPermissions ? userGroupPermissions.map((group: any) => createGroupPermission(group)) : [];

          const contractSuggestedContractOwners = contract?.suggestedContractOwners && contract?.suggestedContractOwners[0]
          const contractOwner: any = contract?.contractOwner

          if (contractSuggestedContractOwners && contract?.contractStatus === "DRAFT") {
            const suggestedUser = {
              saveable: true,
              id: contractSuggestedContractOwners.id,
              name: contractSuggestedContractOwners.firstName + " " + contractSuggestedContractOwners.lastName,
              permissionId: 1,
              permissionName: "Editor",
              email: contractSuggestedContractOwners.email,
              permission: {
                permissionId: 1,
                permissionName: "Editor"
              },
              type: 'user',
            }
            if (!userPermissions.some((e: Permission) => e.email === suggestedUser.email)) {
              usersPermission.push(suggestedUser)
            }
            usersPermission = usersPermission.filter((e: any) => e.email !== contractOwner?.userInfo?.email)
          }

          dispatch(setPermissions(sortPermissions([...usersPermission, ...groupsPermission])));
        } catch (error) {
          console.log(error);

          enqueueSnackbar(translate('error.serverError', 'Something went wrong!'), {
            variant: 'error',
          });
        }
      }
    })();
  }, [contractId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = async (permissionsArg: Permission[]) => {
    try {
      const copyPermissions = [...permissionsArg]
      dispatch(setPermissions(sortPermissions(copyPermissions)))

      const data = copyPermissions.filter(e => e.saveable).map((permission) => {
        if (permission.type === 'user') {
          return {
            userId: +permission.id,
            permissionId: permission.permission.permissionId,
          }
        }
        return {
          userGroupId: +permission.id,
          permissionId: permission.permission.permissionId,
        }
      });
      if (!saveLocal) {
        let type = "contract"
        await privateAxiosInstance.put(`/cs-api/contentPermission/${contractId}?contentType=${type}`, data);

        enqueueSnackbar('Permissions saved successfully', {
          variant: 'success',
        });
      }
      handleClose();
    } catch (error) {
      console.error('Error adding permissions', error);
      enqueueSnackbar('Error adding permissions', {
        variant: 'error',
      });
    }
  };

  const renderedPermissions: Permission[] = []
  // if (permissions.some(e => !renderedPermissions.some(p => p.email === e.email))) {
  //   renderedPermissions.push()
  // }
  permissions.map(e => {
    if (e.type === "user" && !renderedPermissions.some(p => p.email === e.email)) {
      renderedPermissions.push(e)
    }
    if (e.type === "group" && !renderedPermissions.some(p => p.id === e.id)) {
      renderedPermissions.push(e)
    }
  })

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
        {renderedPermissions.slice(0, 5).map((permission, index) => (
          <Box key={permission.id} sx={{ mr: 1 }}>
            <Typography
              aria-owns={openId === permission.id.toString() ? permission.id.toString() : undefined}
              aria-haspopup="true"
              onMouseEnter={(e) => handlePopoverOpen(e, permission.id.toString())}
              onMouseLeave={handlePopoverClose}
            >
              {permission.isGroup && (
                <Badge
                  badgeContent={<GroupIcon sx={{ height: '14px', width: '14px' }} />}
                  color="primary"
                  // sx={{ mr: 1 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  overlap="circular"
                >
                  <Avatar
                    sx={{
                      backgroundColor: getColor(permission.name.charAt(0)),
                      color: 'white',
                    }}
                  >
                    {permission.name.charAt(0).toUpperCase()}
                  </Avatar>
                </Badge>
              )}
              {!permission.isGroup && (
                <Avatar
                  sx={{
                    backgroundColor: getColor(permission.name.charAt(0)),
                    color: 'white',
                  }}
                >
                  {permission.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </Typography>
            <Popover
              id={permission.id.toString()}
              sx={{
                pointerEvents: 'none',
                padding: 0
              }}
              // open={true}
              open={openPopover && openId === permission.id.toString() ? true : false}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ py: 1, px: 1.5, backgroundColor: "rgb(33,43,54)", color: "white" }} >
                <Typography display="block" variant="overline" sx={{ fontWeight: 700, fontSize: ".7rem", padding: 0 }}>
                  {permission.name}
                </Typography>
                <Typography display="block" variant="caption" sx={{ fontWeight: 400, fontSize: ".7rem", padding: 0 }}>
                  {permission.email}
                </Typography>
              </Typography>
            </Popover>
          </Box>
        ))}

        {renderedPermissions.length > 5 && (
          <Avatar sx={{ mr: 1, backgroundColor: '#5e5e5e', color: 'white' }}>
            +{renderedPermissions.length - 5}
          </Avatar>
        )}
      </div>
      <Typography variant="body1" sx={{ color: '#5e5e5e', fontSize: '14px' }}>
        {renderedPermissions
          .slice(0, 5)
          .map((permission) => permission.name)
          .join(', ')}
        {renderedPermissions.length > 5 && `, +${renderedPermissions.length - 5} more`}
      </Typography>
      {isManageAccessButton && (
        <Button
          variant="outlined"
          color="primary"
          sx={{ borderRadius: '2rem', marginTop: '0.5rem' }}
          onClick={handleOnOpen}
        >
          {translate('common.manageAccess', 'Manage access')}
        </Button>
      )}

      {open &&
        <ManageAccessDialog
          open={open}
          onClose={handleClose}
          // @ts-ignore
          permissions={permissions}
          handleSave={handleSave}
          contractId={contractId}
          contractName={contractName}
          userList={userList}
          saveLocal={saveLocal}
        />}
    </div>
  );
};

