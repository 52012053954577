import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ISuggestedParty } from "pages/contracts/contract-types";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

type IPartyType = {
    id: string;
    type: string;
};
export const partyAPI = createApi({
    reducerPath: "API/PARTY",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['partyRole', "parties"],
    endpoints(builder) {
        return {
            fetchPartyRoleSuggestions: builder.query<IPartyType[], void>({
                query() {

                    let endpointURL = `/cs-api/partyRoles/suggestion`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["partyRole"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),
            fetchParties: builder.query<ISuggestedParty[], string>({
                query(organizationUUID) {

                    let endpointURL = `cs-api/parties/suggestion/${organizationUUID}`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["parties"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),

        }
    }
})

export const { useFetchPartyRoleSuggestionsQuery, useFetchPartiesQuery } = partyAPI