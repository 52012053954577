import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

export type IOrganization = {
    id: number;
    uuid: string;
    name: string;
}

export const organizationApi = createApi({
    reducerPath: "API/ORGANIZATION",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['organizations'],
    endpoints(builder) {
        return {
            fetchOrganizations: builder.query<IOrganization[], void>({
                query() {

                    let endpointURL = `/us-api/organizations/list`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["organizations"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),

        }
    }
})

export const { useFetchOrganizationsQuery } = organizationApi