import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from 'redux/axiosBaseQuery';
import { contentApi } from './contentApi';
import { resetContents } from 'redux/slices/contents/contentSlice';

export const contractApi = createApi({
  reducerPath: 'API/CONTRACT',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['contracts'],
  endpoints(builder) {
    return {
      fetchContract: builder.query<Record<string, any>, { contractId?: string }>({
        query({ contractId }) {
          let endpointURL = `/cs-api/contracts/${contractId}`;

          return {
            url: endpointURL,
          };
        },
        transformResponse(response: Record<string, any>) {
          return response.content;
        },
        providesTags: ['contracts'],
      }),
      createContract: builder.mutation<Record<any, any>, FormData>({
        query(formData) {
          const endpointURL = "/cs-api/contracts/file"
          return {
            url: endpointURL,
            method: "POST",
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData
          }
        },
        async onQueryStarted(_, { dispatch }) {
          dispatch(contentApi.util.resetApiState())
        }
      }),
      updateContract: builder.mutation<
        Record<any, any>,
        {
          payload: Record<string, any>;
          contractId: string;
        }
      >({
        query({ contractId, payload }) {
          const endpointURL = `/cs-api/contracts/${contractId}`;
          return {
            url: endpointURL,
            data: payload,
            method: 'PUT',
          };
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            contractApi.util.updateQueryData(
              'fetchContract',
              { contractId: arg.contractId.toString() },
              (draft) => {
                Object.assign(draft, arg.payload);
              }
            )
          );

          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);

            patchResult.undo();
          }
        },
      }),
      terminateContract: builder.mutation<
        Record<any, any>,
        {
          contractId: string;
        }
      >({
        query({ contractId }) {
          const endpointURL = `/cs-api/contracts/terminate/${contractId}`;
          return {
            url: endpointURL,
            method: 'PUT',
          };
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          await dispatch(contractApi.endpoints.fetchContract.initiate({ contractId: arg.contractId }, { forceRefetch: true }))
        },
      }),

    };
  },
});

export const { useFetchContractQuery, useUpdateContractMutation, useTerminateContractMutation, useCreateContractMutation } =
  contractApi;
