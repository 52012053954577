import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode, useEffect } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => {
  const theme = useTheme();
  const isVerifiedTheme = theme?.customPalette?.mode === 'verified';

  return (
    <>
      <Helmet>
        <title>{`${title} | ${isVerifiedTheme ? 'Verified' : 'Tagd'}`}</title>
        {/* <link
          rel="mask-icon"
          href={
            isVerifiedTheme
              ? '/favicon/verified/favicon-16x16.png'
              : '/favicon/tagd/favicon-16x16.png'
          }
        />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={
            isVerifiedTheme
              ? '/favicon/verified/favicon-32x32.png'
              : '/favicon/tagd/favicon-32x32.png'
          }
        />
       
        <link
          rel="apple-touch-icon"
          href={
            isVerifiedTheme
              ? '/favicon/verified/apple-touch-icon.png'
              : '/favicon/tagd/apple-touch-icon.png'
          }
        />

        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={
            isVerifiedTheme
              ? '/favicon/verified/apple-touch-icon.png'
              : '/favicon/tagd/apple-touch-icon.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={
            isVerifiedTheme
              ? '/favicon/verified/apple-touch-icon.png'
              : '/favicon/tagd/apple-touch-icon.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={
            isVerifiedTheme
              ? '/favicon/verified/apple-touch-icon.png'
              : '/favicon/tagd/apple-touch-icon.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={
            isVerifiedTheme
              ? '/favicon/verified/apple-touch-icon.png'
              : '/favicon/tagd/apple-touch-icon.png'
          }
        /> */}
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

export default Page;
